import React from "react";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { css } from "../../modules/stitches";
import { useStore } from "../../../common/store/index";

export const About = () => {
  const { lang } = useStore();
  return useObserver(() => (
    <div className={`${styles.about} mt-10`}>
      <div className="about-content px-4 md:px-20 lg:px-24 xl:px-24 py-8 md:py-20 ">
        <div className="title text-xl md:text-4xl mb-4 md:mb-6 font-normal">{lang.t("about.title")}</div>
        <p className="text-md md:text-lg leading-7 md:leading-9 md:font-light">{lang.t("about.p1")}</p>
        <p className="text-md md:text-lg leading-7 md:leading-9 md:font-light">{lang.t("about.p2")}</p>
        <p className="text-md md:text-lg leading-7 md:leading-9 md:font-light">{lang.t("about.p3")}</p>
        <p className="text-md md:text-lg leading-7 md:leading-9 md:font-light">{lang.t("about.p4")}</p>
        <div className="line my-6 md:my-12"></div>
        <div className="bottom-links flex items-center justify-center mb-6 md:mb-12">
          <a href="https://iotex.io/" target="_blank">
            <img src="/image/icon01.png" alt="" />
          </a>
          <a href="https://twitter.com/iotex_io" target="_blank">
            <img src="/image/icon02.png" alt="" />
          </a>
          <a href="https://t.me/IoTeXGroup" target="_blank">
            <img src="/image/icon03.png" alt="" />
          </a>
          <a href="https://www.reddit.com/r/IoTeX" target="_blank">
            <img src="/image/icon04.png" alt="" />
          </a>
          <a href="https://medium.com/@iotex" target="_blank">
            <img src="/image/icon05.png" alt="" />
          </a>
        </div>
        <div className="text-sm md:text-base text-center">©{new Date().getFullYear()} IoTeX</div>
      </div>
    </div>
  ));
};

const styles = {
  about: css({
    width: "100%",
    backgroundImage: "url(image/bg_map.png)",
    backgroundSize: "100%",
    backgroundPosition: "50% 10%",
    backgroundRepeat: "no-repeat",
    lg: {
      backgroundSize: "80%",
      backgroundPosition: "50% 10%",
    },
    ".about-content": {
      margin: "2rem auto",
      width: "90%",
      boxSizing: "border-box",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      md: {
        width: "90%",
        margin: "5rem auto",
      },
      lg: {
        width: "70%",
      },
      xl: {
        width: "54rem",
      },
    },
    ".title": {
      lineHeight: "1.4",
    },
    ".line": {
      width: "100%",
      height: "1px",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    ".bottom-links": {
      a: {
        marginRight: "1.2rem",
        cursor: "pointer",
        "&:last-child": {
          marginRight: 0,
        },
        md: {
          marginRight: "3rem",
        },
      },
      img: {
        width: "2rem",
        height: "2rem",
      },
    },
  }),
};

import React, { useRef, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

import { useStore } from "../../../common/store/index";

function MapChart() {
  const { burnDrop } = useStore();

  // const toExplorerDetail = (hash) => {
  //   history.push(`/explorer/${hash}`)
  // }

  return <div id="chartdiv" style={{ width: "100%", height: "100%" }}></div>;
}
export default MapChart;

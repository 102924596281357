import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { css } from "../../modules/stitches";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../common/store/index";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { getName } from "country-list";

export const Explorer = () => {
  const history = useHistory();
  const { burnDrop, lang } = useStore();

  const columns = [
    {
      title: lang.t("hash"),
      dataIndex: "hash",
      key: "hash",
    },
    {
      title: lang.t("age"),
      dataIndex: "batch_id",
      key: "batch_id",
      render(value, record) {
        return <span>{dayjs(value, "yyyymmdd").format("YYYY-MM-DD")}</span>;
      },
    },
    {
      title: lang.t("region"),
      dataIndex: "country",
      key: "country",
      render(value, record) {
        return <span>{getName(value)}</span>;
      },
    },
  ];

  const store = useLocalStore(() => ({
    get total() {
      return burnDrop.listDevices.count;
    },
    get loading() {
      return burnDrop.listDevices.loading;
    },
    get data() {
      return burnDrop.listDevices.data;
    },
  }));

  useEffect(() => {
    burnDrop.loadListDeviceData();
  }, []);

  return useObserver(() => (
    <div className={`px-4 md:px-12 lg:px-12 xl:px-24 ${styles.explorer}`}>
      <div className="text-2xl text-left font-thin mb-4">{lang.t("blocks")}</div>
      <div className={styles.explorerTable}>
        <Table
          columns={columns}
          dataSource={store.data}
          loading={store.loading}
          pagination={{
            total: store.total,
            onChange(page) {
              burnDrop.loadListDeviceData({ page });
            },
          }}
          onRow={(record) => {
            return {
              onClick: (event) => {
                history.push(`/explorer/${record.id}`);
              },
            };
          }}
        />
      </div>
    </div>
  ));
};

const styles = {
  explorer: css({
    textAlign: "center",
    pt: "5vh",
    margin: "0 auto 2vh",
    width: "100%",
  }),
  explorerTable: css({
    ".ant-table": {
      background: "rgba(255, 255, 255, 0.05)",
      color: "#fff",
    },
    ".ant-table-tbody > tr > td": {
      borderBottom: 0,
    },
    ".ant-table-tbody > tr.ant-table-row:hover > td": {
      background: "rgba(255, 255, 255, 0.4)",
      fontSize: "0.88rem",
    },
    ".ant-table-thead > tr > th": {
      background: "rgba(255, 255, 255, 0)",
      borderBottom: 0,
      color: "#fff",
      fontSize: "1rem",
    },
    ".ant-table-pagination.ant-pagination": {
      justifyContent: "center",
      marginTop: "6vh",
    },
    ".ant-pagination-disabled .ant-pagination-item-link": {
      color: "#fff",
      backgroundColor: "rgba(255, 255, 255, 0)",
      border: "none",
      fontSize: "1.44rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".ant-pagination-item-active": {
      background: "rgba(255,255,255,0.05)",
      borderColor: "transparent",
      a: {
        color: "#fff",
      },
    },
  }),
};

import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Button, Dropdown, Menu, Input, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
import { SearchOutlined, CaretDownOutlined } from "@ant-design/icons";
import { css } from "../../modules/stitches";
import { useStore } from "../../../common/store/index";
import { _ } from "../../../common/utils/lodash";

const { Search } = Input;

export const Header = () => {
  const history = useHistory();
  const [path, setPath] = useState(history.location.pathname);
  const [showTooltips, setShowTooltips] = useState(false);
  const { burnDrop, lang } = useStore();
  const [langText, setLanguage] = useState(lang.t("english"));

  const onSearch = (e) => {
    if (e.target.value.length !== 20) {
      setShowTooltips(true);
      return false;
    } else {
      setShowTooltips(false);
      burnDrop
        .searchDevice({ uid: e.target.value })
        .then((res) => {
          if (res) {
            setShowTooltips(false);
          } else {
            setShowTooltips(true);
          }
        })
        .catch((err) => {
          setShowTooltips(true);
        });
    }
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/">{lang.t("dashboard")}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/explorer">{lang.t("explorer")}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/about">{lang.t("about")}</Link>
      </Menu.Item>
      <Menu.Item onClick={(e) => globalThis.shareTwitter()}>{lang.t("share")}</Menu.Item>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("english"));
          lang.setLang("en");
        }}
      >
        {lang.t("english")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("chinese"));
          lang.setLang("zh_CN");
        }}
      >
        {lang.t("chinese")}
      </Menu.Item>
    </Menu>
  );

  const languageMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("english"));
          lang.setLang("en");
        }}
      >
        {lang.t("english")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("chinese"));
          lang.setLang("zh_CN");
        }}
      >
        {lang.t("chinese")}
      </Menu.Item>
    </Menu>
  );

  return useObserver(() => (
    <header className="px-4 md:px-12 lg:px-12 xl:px-24 box-content">
      <div className="flex items-center justify-between h-10 md:h-16 md:border-b-2 md:border-solid md:border-white md:border-opacity-25">
        <Link to="/" onClick={() => setPath("/")}>
          <img className="h-6 md:h-8" src={"/image/logo.png"} />
        </Link>
        <ul className={`${styles.nav} hidden md:flex items-center mb-0`}>
          <li onClick={() => setPath("/")}>
            <Link to="/">{lang.t("dashboard")}</Link>
            <span className={path === "/" ? "active" : ""}></span>
          </li>
          <li onClick={() => setPath("/explorer")}>
            <Link to="/explorer">{lang.t("explorer")}</Link>
            <span className={path === "/explorer" ? "active" : ""}></span>
          </li>
          <li onClick={() => setPath("/about")}>
            <Link to="/about">{lang.t("about")}</Link>
            <span className={path === "/about" ? "active" : ""}></span>
          </li>
          <li>
            <div className="flex items-center">
              <img style={{ width: "28px", marginRight: "10px" }} src="/image/icon_twiiter.png" alt="" />
              <a onClick={(e) => globalThis.shareTwitter()}>{lang.t("share")}</a>
            </div>
          </li>
          <li className={styles.search}>
            {
              //@ts-ignore
              <Input placeholder={lang.t("placeholder")} onChange={onSearch} suffix={<SearchOutlined />} />
            }
            {showTooltips ? <div className="errorTips">{lang.t("invalid")}</div> : ""}
          </li>
          <li>
            <Dropdown overlay={languageMenu} arrow placement="bottomCenter">
              <a className="language-menu flex items-center" onClick={(e) => e.preventDefault()}>
                {langText}
                {
                  //@ts-ignore
                  <CaretDownOutlined style={{ color: "#ffff", marginLeft: 5 }} />
                }
              </a>
            </Dropdown>
          </li>
        </ul>
        <div className={`block md:hidden ${styles.mobileMenu}`}>
          <Dropdown className="dropdown__content w-20" trigger={["click", "hover"]} overlay={menu} placement="bottomLeft" getPopupContainer={() => document.querySelector(".dropdown__content")}>
            <Button type="text" icon={<img src={"/image/menue.png"} />}></Button>
          </Dropdown>
        </div>
      </div>
      <div className={`mt-2 block md:hidden ${styles.search}`}>
        {
          //@ts-ignore
          <Input placeholder={lang.t("placeholder")} onChange={onSearch} suffix={<SearchOutlined />} />
        }
        {showTooltips ? <div className="errorTips">{lang.t("invalid")}</div> : ""}
      </div>
    </header>
  ));
};

const styles = {
  nav: css({
    li: {
      height: "100%",
      textAlign: "center",
      position: "relative",
      cursor: "pointer",
      md: {
        marginLeft: "1rem",
      },
      lg: {
        marginLeft: "3rem",
      },
      xl: {
        marginLeft: "4rem",
      },
      "&:last-child": {
        lg: {
          marginLeft: "1rem",
        },
        xl: {
          marginLeft: "2rem",
        },
      },
      a: {
        height: "100%",
        color: "$white",
        fontSize: "1.25rem",
        "&:hover, &:focus": {
          color: "#03FFE1",
        },
        md: {
          fontSize: "1rem",
        },
      },
      ".active": {
        position: "absolute",
        display: "block",
        width: "3.75rem",
        height: "0.25rem",
        left: "calc((100% - 3.75rem) / 2)",
        bottom: "-1.5rem",
        backgroundColor: "#BEF7FF",
        md: {
          bottom: "-1.25rem",
        },
      },
      ".language-menu": {
        color: "#fff",
        fontWeight: 500,
      },
    },
  }),
  mobileMenu: css({
    ".ant-btn-icon-only": {
      width: "1.5rem",
      height: "1.5rem",
      paddingTop: "0",
    },
    ".ant-dropdown-menu ": {
      width: "10rem",
      padding: 0,
      backgroundColor: "$white",
      color: "#595959",
      borderRadius: "10px",
      overflow: "hidden",
      ".ant-dropdown-menu-item": {
        lineHeight: "2.25",
        a: {
          color: "#595959",
          "&:hover, &:focus": {
            backgroundColor: "#E0E0E0",
          },
        },
      },
    },
  }),
  search: css({
    width: "100%",
    position: "relative",
    md: {
      width: "14rem",
    },
    lg: {
      width: "12rem",
    },
    xl: {
      width: "20rem",
    },
    ".ant-btn-icon-only > *": {
      fontSize: "18px",
      lg: {
        fontSize: "24px",
      },
    },
    ".ant-input-affix-wrapper": {
      border: "none",
      height: "2rem",
      borderRadius: "1.56rem",
      background: "rgba(255, 255, 255, 0.2)",
      color: "$white",
      padding: "0 0 0 0.72rem",
      lg: {
        height: "2rem",
        borderRadius: "2.13rem",
        padding: "0 0 0 1.41rem",
      },
      "&:hover": {
        borderColor: "rgba(0,0,0,0)",
        boxShadow: "none",
      },
      "&:focus": {
        borderColor: "rgba(0,0,0,0)",
        boxShadow: "none",
      },
      ".ant-input": {
        height: "100%",
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
        color: "$white",
      },
      ".ant-input-suffix": {
        fontSize: "16px",
        width: "30px",
        backgroundColor: "rgba(0,0,0,0)",
        ".ant-btn-icon-only": {
          backgroundColor: "rgba(0,0,0,0)",
          border: "none",
          height: "100%",
          fontSize: "12px",
          marginRight: "0.35rem",
          lg: {
            marginRight: "0.88rem",
          },
        },
        ".ant-input-search-button:not(.ant-btn-primary)": {
          color: "$white",
          "&:hover": {
            borderColor: "rgba(0,0,0,0)",
            boxShadow: "none",
          },
          "&:focus": {
            borderColor: "rgba(0,0,0,0)",
            boxShadow: "none",
          },
        },
      },
    },
    ".errorTips": {
      position: "absolute",
      width: "6rem",
      height: "2.385rem",
      lineHeight: "3rem",
      bottom: "-42px",
      backgroundImage: `url(/image/errortips.png)`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      textAlign: "center",
      fontSize: "0.875",
      color: "#000",
    },
  }),
};

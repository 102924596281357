import React, { useEffect } from "react";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { css } from "../../modules/stitches";
import { Table } from "antd";
import { useRouter } from "../../utils/react-hooks";
import { rpcClient } from "../../utils/rpc";
import { activated_devices } from "@generated/burndropService";
import dayjs from "dayjs";
import { getName } from "country-list";
import { useStore } from "../../../common/store/index";

export const ExplorerDetail = () => {
  const { lang } = useStore();
  const router = useRouter();
  console.log(router.query);
  const store = useLocalStore(() => ({
    data: {} as activated_devices,
    async loadDevice() {
      const data = await rpcClient.getDeviceById({ id: router.query.id });
      store.data = data;
    },
  }));

  useEffect(() => {
    store.loadDevice();
  }, []);
  return useObserver(() => (
    <div className={`px-4 md:px-12 lg:px-12 xl:px-24 ${styles.explorer}`}>
      <div className="text-2xl text-left font-thin mb-4">
        {lang.t("device.hash")} {store.data?.hash}
      </div>
      <div className={styles.explorerTable}>
        <ul>
          <li>
            <p className="explorerTableLabel">{lang.t("age")}</p>
            <p>{dayjs(store.data?.batch_id, "yyyymmdd").format("YYYY-MM-DD")}</p>
          </li>
          <li>
            <p className="explorerTableLabel">{lang.t("region")}</p>
            {store.data?.country && <p>{getName(store.data?.country)}</p>}
          </li>
        </ul>
      </div>
    </div>
  ));
};

const styles = {
  explorer: css({
    textAlign: "center",
    pt: "5vh",
    margin: "0 auto 2vh",
    width: "100%",
  }),
  explorerTable: css({
    width: "100%",
    background: "rgba(255, 255, 255, 0.05)",
    color: "#fff",
    ul: {
      padding: "0 24px",
      li: {
        height: "60px",
        lineHeight: "60px",
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        ".explorerTableLabel": {
          width: "240px",
          marginBottom: 0,
        },
      },
    },
  }),
};

import remotedev from "mobx-remotedev";
import { observable, action, computed } from "mobx";
import { rpcClient } from "../../client/utils/rpc";
import { utils } from "../utils/index";
import { DeviceData } from "../../server/modules/wildcardAPI/burndrop.endpoints";
import { _ } from "../utils/lodash";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4core from "@amcharts/amcharts4/core";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import { rootStore } from "./index";
import dayjs from "dayjs";
import { publicConfig } from "../../../configs/public";
import { activated_devices } from "@generated/burndropService";
import facebookImg from "../../client/static/image/icon_facebook.png";
import telegramImg from "../../client/static/image/icon_telegram.png";
import twiiterImg from "../../client/static/image/icon_twiiter.png";
import { tw, fbButton, telegram } from "vanilla-sharing";
import BigNumber from "bignumber.js";

utils.env.onBrowser(() => {
  const window = globalThis;
  window.shareTwitter = () => {
    tw({
      url: publicConfig.shareConfig.url,
      title: publicConfig.shareConfig.shareTitle,
    });
  };
  window.shareFacebook = () => {
    fbButton({
      url: publicConfig.shareConfig.url,
    });
  };
  window.shareTelegram = () => {
    telegram({
      url: publicConfig.shareConfig.url,
      title: publicConfig.shareConfig.title,
    });
  };
});

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);
export type Mapdata = { date: string; list: DeviceData[] };
export type ListDeviceData = { id: number; hash: string; country: string; batch_id: string };

// @remotedev({ name: "burndrop", onlyActions: true })
export class BurnDropStore {
  @observable states: { [key: string]: string } = {};
  @observable batchDevices: Mapdata[] = [];
  @observable totalDevices: Mapdata[] = [];
  @observable weekDevices: Mapdata[] = [];
  bubbleSeries: am4maps.MapImageSeries;
  imageTemplate: am4maps.MapImage;
  mapFilterSwitch: am4core.SwitchButton;

  @observable listDevices: {
    hasMore: boolean;
    data: ListDeviceData[];
    count: number;
    loading: boolean;
  } = { hasMore: true, data: [], count: 0, loading: false };

  mapApi: Partial<{
    polygonSeries: am4maps.MapPolygonSeries;
    selectCountry: Function;
  }> = {};

  @observable search: {
    result: activated_devices | null;
  } = { result: null };

  @observable viewType: "all" | "todayDelta" | "week" = "all";
  @observable currentWeek = "";

  @computed
  get currentWeekData() {
    return this.weekDevices.find((i) => i.date == this.currentWeek);
  }

  @computed
  get todayData() {
    return this.batchDevices.find((i) => i.date == this.today);
  }

  today = publicConfig.IS_PROD ? dayjs().format("YYYY-MM-DD") : dayjs("2020-10-28").format("YYYY-MM-DD");

  @action.bound
  async loadLatestStats() {
    try {
      const [states, totalData] = await Promise.all([rpcClient.getKVState(), rpcClient.getLatestBurn()]);
      //@ts-ignore
      this.states = { ...states, TotalBurnIOTX: totalData[0]["sum(burnt)"] };
    } catch (error) {
      utils.helper.error.requestErrorHanding({ error });
    }
  }

  @computed
  get devices(): Mapdata[] {
    return this.totalDevices;
  }

  @action.bound
  async searchDevice({ uid }: { uid: string }) {
    try {
      const data = await rpcClient.searchDevice({ uid });
      if (data) {
        this.search.result = data;
        if (data.country) {
          const polygon = this.mapApi.polygonSeries?.getPolygonById(data.country);
          if (polygon) {
            this.mapApi.selectCountry(polygon);
            this.bubbleSeries.mapImages.each((i) => {
              //@ts-ignore
              if (i.tooltipDataItem.dataContext.id == data.country) {
                setTimeout(() => {
                  i.showTooltip();
                }, 1000);
              }
            });
          }
        }
      }
      return !!data;
    } catch (error) {
      utils.helper.error.requestErrorHanding({ error });
      return false;
    }
  }

  @action.bound
  async loadDevices() {
    try {
      const [batchData, totalData, weekData] = await Promise.all([rpcClient.getDevices({ type: "byBatchID" }), rpcClient.getDevices({ type: "byTotal" }), rpcClient.getDevices({ type: "byWeek" })]);
      if (batchData) {
        rootStore.burnDrop.batchDevices = _.map(_.groupBy(batchData, "date"), (v, k) => {
          return {
            date: k,
            list: v,
          };
        });
      }
      if (totalData) {
        rootStore.burnDrop.totalDevices = _.map(_.groupBy(totalData, "date"), (v, k) => {
          return {
            date: k,
            list: v,
          };
        });
      }
      if (weekData) {
        rootStore.burnDrop.weekDevices = _.map(_.groupBy(weekData, "date"), (v, k) => {
          return {
            date: k,
            list: v,
          };
        });
      }
    } catch (error) {
      utils.helper.error.requestErrorHanding({ error });
    }
  }

  @action.bound
  async loadListDeviceData({ page = 0 }: { page?: number } = {}) {
    try {
      if (this.listDevices.loading) return;
      this.listDevices.loading = true;
      const { data, hasMore, count } = await rpcClient.getDeviceList({
        orderBy: { batch_id: "desc" },
        select: { id: true, hash: true, country: true, batch_id: true },
        skip: page * 10,
      });
      this.listDevices.loading = false;
      this.listDevices.hasMore = hasMore;
      this.listDevices.data = data;
      this.listDevices.count = count;
    } catch (error) {
      utils.helper.error.requestErrorHanding({ error });
    }
  }

  @action.bound
  render() {
    // TODO: hanle ui switch
    if (this.viewType == "todayDelta") {
      const newMapData = this.batchDevices.find((i) => i.date == this.today);
      if (newMapData) {
        this.bubbleSeries.dataFields.value = "delta";
        this.bubbleSeries.data = newMapData.list;
      }
    }
    if (this.viewType == "all") {
      const newMapData = this.totalDevices[rootStore.burnDrop.totalDevices.length - 1];
      if (!newMapData) return;
      this.bubbleSeries.dataFields.value = "total";
      this.bubbleSeries.data = newMapData.list;
      // this.mapFilterSwitch.dom["children"][0]["style"]["opacity"] = 1;
    }
    if (this.viewType == "week") {
      const newMapdata = this.weekDevices.find((i) => i.date == this.currentWeek);
      if (newMapdata) {
        this.bubbleSeries.dataFields.value = "delta";
        this.bubbleSeries.data = newMapdata.list;
        // this.mapFilterSwitch.isShowing = false;
        // this.mapFilterSwitch.dom["children"][0]["style"]["opacity"] = 0;
      }
    }
  }

  @action.bound
  selectWeek(week: string) {
    this.viewType = "week";
    this.currentWeek = week;
    this.render();
  }

  @action.bound
  selectViewType(type: BurnDropStore["viewType"]) {
    this.viewType = type;
    this.render();
  }

  getToolTipTemplate(text?, target?) {
    const data = target?.tooltipDataItem?.dataContext;
    const country = rootStore.burnDrop.search?.result?.country;
    const selected = country == data?.id;
    if (!data.name) {
      const polygon = this.mapApi.polygonSeries.getPolygonById(data.id);
      //@ts-ignore
      data.name = polygon.dataItem.dataContext.name;
    }
    return `<div style="padding-bottom:15px;">
       ${
         selected
           ? ` <p style="width: 50px;height: 4px;background: #03FFE1;margin-top: 15px;border-radius: 10px;"></p>
            <div style="font-size: 16px;line-height: 20px;font-weight:500;margin-bottom: 10px;">Hash：</div>
            <a href="${window.location.href}explorer/${rootStore.burnDrop.search?.result?.id}" style="font-size: 14px;color: #03FFE1; cursor: pointer;text-decoration: underline;">
              97606ce555b08fe6f357e4b65<br/>29462001f9b3f24d316ec9eb77<br/>1e305f77a35b2
            </a>`
           : ""
       }
        <div style="display: flex; align-items: center;margin-bottom:16px;margin-top:10px;">
          <span style="display: block; width: 14px;height: 14px;margin-right: 8px;background: #03FFE1;border-radius: 50%;"></span>
          <span style="font-size: 14px;font-weight:500;">{name}</span>
        </div>
        <p style="font-size: 18px;margin-bottom: 5px;">{value}</p>
        <p style="font-size: 12px;margin-bottom: 15px;">Devices on IoTeX Platform</p>
        <div style="width: 100%;display:flex;align-items: flex-start;">
          <img src="${facebookImg}" onclick="shareFacebook()" style="display:inline-block;width:25px;height:25px;margin-right:10px;cursor:pointer;" alt="" />
          <img src="${twiiterImg}" onclick="shareTwitter()" style="display:inline-block;width:25px;height:25px;margin-right:10px;cursor:pointer;" alt="" />
          <img src="${telegramImg}" onclick="shareTelegram()" style="display:inline-block;width:25px;height:25px;cursor:pointer;" alt="" />
        </div>
      </div>`;
  }

  @action.bound
  initMap() {
    am4core.ready(() => {
      let basicColor = "#03FFE1";
      let bubbleCircleColor = am4core.color(basicColor);
      let activeColor = am4core.color("#ff8726");
      let confirmedColor = am4core.color("#d21a1a");
      let recoveredColor = am4core.color("#45d21a");
      let deathsColor = am4core.color("#1c5fe5");

      // for an easier access by key
      let colors = { active: activeColor, confirmed: confirmedColor, recovered: recoveredColor, deaths: deathsColor };

      let countryColor = am4core.color("rgba(255,255,255,0.1)");
      let countryStrokeColor = am4core.color("rgba(0,0,0,0.5)");
      let countryHoverColor = am4core.color("rgba(255,255,255,0.1)");
      let activeCountryColor = am4core.color("rgba(255,255,255,0.1)");

      let currentPolygon;

      let countryDataTimeout;

      let currentTypeName;

      let animation;
      let timer = null;

      let current = "globe";

      // main container
      let container = am4core.create("chartdiv", am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.tooltip = new am4core.Tooltip();
      container.tooltip.background.fill = am4core.color("#000000");
      container.tooltip.background.stroke = activeColor;
      container.tooltip.fontSize = "0.9em";
      container.tooltip.getFillFromObject = false;
      container.tooltip.getStrokeFromObject = false;

      // MAP CHART
      let mapChart = container.createChild(am4maps.MapChart);
      // map zoom
      mapChart.zoomControl = new am4maps.ZoomControl();
      mapChart.zoomControl.align = "right";
      mapChart.zoomControl.marginRight = 40;
      mapChart.zoomControl.valign = "bottom";
      mapChart.zoomControl.marginBottom = 40;
      mapChart.zoomControl.width = 40;
      mapChart.zoomControl.height = 40;
      mapChart.zoomControl.fontSize = 14;
      mapChart.zoomControl.strokeWidth = 0;

      mapChart.zoomControl.minusButton.events.on("hit", showWorld);
      mapChart.seriesContainer.background.events.on("hit", showWorld);
      mapChart.seriesContainer.background.events.on("over", resetHover);
      mapChart.seriesContainer.background.fillOpacity = 0;
      mapChart.zoomEasing = am4core.ease.sinOut;

      mapChart.geodata = am4geodata_worldLow;

      mapChart.projection = new am4maps.projections.Orthographic();
      mapChart.panBehavior = "rotateLongLat";
      mapChart.deltaLatitude = -20;
      mapChart.padding(20, 20, 20, 20);

      // limits vertical rotation
      mapChart.adapter.add("deltaLatitude", function (delatLatitude) {
        return am4core.math.fitToRange(delatLatitude, -90, 90);
      });

      let graticuleSeries = mapChart.series.push(new am4maps.GraticuleSeries());
      graticuleSeries.mapLines.template.line.stroke = am4core.color("#ffffff");
      graticuleSeries.mapLines.template.line.strokeOpacity = 0;
      graticuleSeries.fitExtent = false;

      timer = setTimeout(function () {
        animation = mapChart.animate({ property: "deltaLongitude", to: 100000 }, 20000000);
      }, 2000);

      mapChart.seriesContainer.events.on("down", function () {
        if (timer) {
          clearTimeout(timer);
          timer = null;
          animation.stop();
        } else {
          if (current === "globe") {
            timer = setTimeout(function () {
              animation = mapChart.animate({ property: "deltaLongitude", to: 100000 }, 20000000);
            }, 2000);
          }
        }
      });

      // when map is globe, beackground is made visible
      mapChart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0.05;
      mapChart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#ffffff");
      mapChart.backgroundSeries.hidden = false;

      // Map polygon series (defines how country areas look and behave)
      let polygonSeries = mapChart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.dataFields.id = "id";
      polygonSeries.dataFields.value = "confirmedPC";
      polygonSeries.interpolationDuration = 0;

      polygonSeries.useGeodata = true;
      polygonSeries.nonScalingStroke = true;
      polygonSeries.strokeWidth = 0.5;
      polygonSeries.calculateVisualCenter = true;

      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.fill = countryColor;
      polygonTemplate.fillOpacity = 1;
      polygonTemplate.stroke = countryStrokeColor;
      polygonTemplate.strokeOpacity = 0.15;
      polygonTemplate.setStateOnChildren = true;
      polygonTemplate.tooltipPosition = "fixed";

      polygonTemplate.events.on("hit", handleCountryHit);
      polygonTemplate.events.on("over", handleCountryOver);
      polygonTemplate.events.on("out", handleCountryOut);

      polygonSeries.heatRules.push({
        target: polygonTemplate,
        property: "fill",
        min: countryColor,
        max: countryColor,
        dataField: "value",
      });

      // polygon states
      let polygonHoverState = polygonTemplate.states.create("hover");
      polygonHoverState.transitionDuration = 1400;
      polygonHoverState.properties.fill = countryHoverColor;

      let polygonActiveState = polygonTemplate.states.create("active");
      polygonActiveState.properties.fill = activeCountryColor;

      // Bubble series
      let bubbleSeries = mapChart.series.push(new am4maps.MapImageSeries());
      rootStore.burnDrop.bubbleSeries = bubbleSeries;
      // bubbleSeries.data = JSON.parse(JSON.stringify(mapData));

      bubbleSeries.dataFields.value = "total";
      bubbleSeries.dataFields.id = "id";

      // adjust tooltip
      bubbleSeries.tooltip.animationDuration = 0;
      bubbleSeries.tooltip.showInViewport = false;
      bubbleSeries.tooltip.background.fillOpacity = 0.2;
      bubbleSeries.tooltip.getStrokeFromObject = true;
      bubbleSeries.tooltip.getFillFromObject = false;
      bubbleSeries.tooltip.background.fillOpacity = 0.2;
      bubbleSeries.tooltip.background.fill = am4core.color("#000000");
      bubbleSeries.tooltip.pointerOrientation = "left";
      bubbleSeries.tooltip.maxWidth = 211;
      bubbleSeries.tooltip.dx = 0;
      bubbleSeries.tooltip.dy = 10;
      bubbleSeries.tooltip.label.interactionsEnabled = true;
      bubbleSeries.tooltip.keepTargetHover = true;

      // first child is circle
      let imageTemplate = bubbleSeries.mapImages.template;
      rootStore.burnDrop.imageTemplate = imageTemplate;
      // if you want bubbles to become bigger when zoomed, set this to false tooltips
      imageTemplate.nonScaling = true;
      imageTemplate.strokeOpacity = 0;

      imageTemplate.fillOpacity = 0.8;
      imageTemplate.fill = bubbleCircleColor;
      imageTemplate.stroke = bubbleCircleColor;
      const that = this;
      imageTemplate.events.on("over", handleImageOver);
      imageTemplate.events.on("out", handleImageOut);
      imageTemplate.events.on("hit", handleImageHit);
      imageTemplate.adapter.add("tooltipHTML", function (text, target) {
        return that.getToolTipTemplate(text, target);
      });

      // this is needed for the tooltip to point to the top of the circle instead of the middle
      imageTemplate.adapter.add("tooltipY", function (tooltipY, target) {
        return -target.children.getIndex(0)["radius"];
      });

      // When hovered, circles become non-opaque
      let imageHoverState = imageTemplate.states.create("hover");
      imageHoverState.properties.fillOpacity = 1;

      // add circle inside the image
      let circle = imageTemplate.createChild(am4core.Circle);
      circle.hiddenState.properties.scale = 0.0001;
      circle.hiddenState.transitionDuration = 2000;
      circle.defaultState.transitionDuration = 2000;
      circle.defaultState.transitionEasing = am4core.ease.elasticOut;
      circle.applyOnClones = true;

      // heat rule makes the bubbles to be of a different width. Adjust min/max for smaller/bigger radius of a bubble
      bubbleSeries.heatRules.push({
        target: circle,
        property: "radius",
        min: 8,
        max: 30,
        dataField: "value",
      });

      // when data items validated, hide 0 value bubbles (because min size is set)
      bubbleSeries.events.on("dataitemsvalidated", function () {
        bubbleSeries.dataItems.each((dataItem) => {
          let mapImage = dataItem.mapImage;
          let circle = mapImage.children.getIndex(0);
          if (mapImage.dataItem.value == 0) {
            circle.hide(0);
          } else if (circle.isHidden || circle.isHiding) {
            circle.show();
          }
        });
      });

      // this places bubbles at the visual center of a country
      imageTemplate.adapter.add("latitude", function (latitude, target) {
        let polygon = polygonSeries.getPolygonById(target.dataItem.id);
        if (polygon) {
          target.disabled = false;
          return polygon.visualLatitude;
        } else {
          target.disabled = true;
        }
        return latitude;
      });

      imageTemplate.adapter.add("longitude", function (longitude, target) {
        let polygon = polygonSeries.getPolygonById(target.dataItem.id);
        if (polygon) {
          target.disabled = false;
          return polygon.visualLongitude;
        } else {
          target.disabled = true;
        }
        return longitude;
      });
      // END OF MAP

      // switch between map and globe
      let mapGlobeSwitch = mapChart.createChild(am4core.SwitchButton);
      mapGlobeSwitch.align = "right";
      mapGlobeSwitch.y = -10;
      mapGlobeSwitch.marginRight = am4core.percent(5.5);
      mapGlobeSwitch.leftLabel.text = rootStore.lang.t("globe");
      mapGlobeSwitch.leftLabel.fill = am4core.color("#ffffff");
      mapGlobeSwitch.rightLabel.fill = am4core.color("#ffffff");
      mapGlobeSwitch.rightLabel.text = rootStore.lang.t("map");
      mapGlobeSwitch.verticalCenter = "top";
      mapGlobeSwitch.fill = am4core.color("#000");
      mapGlobeSwitch.dom.id = "type-switch-btn";
      mapGlobeSwitch.dom["children"][0]["children"][1]["children"][0]["style"]["stroke-opacity"] = 0;
      mapGlobeSwitch.dom["children"][0]["children"][1]["children"][0]["style"]["fill"] = "rgba(255, 255, 255, 0.1)";
      mapGlobeSwitch.dom["children"][0]["children"][1]["children"][1]["children"][0]["style"]["fill"] = "rgba(255, 255, 255, 1)";

      mapGlobeSwitch.events.on("toggled", function () {
        if (mapGlobeSwitch.isActive) {
          current = "map";
          clearTimeout(timer);
          if (animation) animation.stop();
          mapChart.projection = new am4maps.projections.Miller();
          mapChart.panBehavior = "move";
          mapChart.backgroundSeries.hide();
          mapChart.padding(0, 0, 0, 0);
          mapChart.deltaLongitude = 0;
        } else {
          current = "globe";
          mapChart.projection = new am4maps.projections.Orthographic();
          mapChart.panBehavior = "rotateLongLat";
          mapChart.backgroundSeries.show();
          mapChart.padding(20, 20, 20, 20);
          mapChart.deltaLatitude = -20;

          timer = setTimeout(function () {
            animation = mapChart.animate({ property: "deltaLongitude", to: 100000 }, 20000000);
          }, 2000);

          mapChart.seriesContainer.events.on("down", function () {
            if (animation) {
              animation.stop();
            }
          });
        }
      });

      // switch between all and today
      // let mapFilterSwitch = mapChart.createChild(am4core.SwitchButton);
      // mapFilterSwitch.align = "left";
      // mapFilterSwitch.marginLeft = am4core.percent(5.5);
      // mapFilterSwitch.y = -10;
      // mapFilterSwitch.leftLabel.text = "All";
      // mapFilterSwitch.leftLabel.fill = am4core.color("#ffffff");
      // mapFilterSwitch.rightLabel.fill = am4core.color("#ffffff");
      // mapFilterSwitch.rightLabel.text = "Today";
      // mapFilterSwitch.verticalCenter = "top";
      // mapFilterSwitch.dom["children"][0]["children"][1]["children"][0]["style"]["stroke-opacity"] = 0;
      // mapFilterSwitch.dom["children"][0]["children"][1]["children"][0]["style"]["fill"] = "rgba(255, 255, 255, 0.1)";
      // mapFilterSwitch.dom["children"][0]["children"][1]["children"][1]["children"][0]["style"]["fill"] = "rgba(255, 255, 255, 1)";
      // rootStore.burnDrop.mapFilterSwitch = mapFilterSwitch;
      // mapFilterSwitch.events.on("toggled", function () {
      //   if (mapFilterSwitch.isActive) {
      //     rootStore.burnDrop.selectViewType("todayDelta");
      //   } else {
      //     rootStore.burnDrop.selectViewType("all");
      //   }
      // });

      // buttons & chart container
      let buttonsAndChartContainer = container.createChild(am4core.Container);
      buttonsAndChartContainer.layout = "vertical";
      buttonsAndChartContainer.height = am4core.percent(45); // make this bigger if you want more space for the chart
      buttonsAndChartContainer.width = am4core.percent(100);
      buttonsAndChartContainer.valign = "bottom";

      // country name and buttons container
      let nameAndButtonsContainer = buttonsAndChartContainer.createChild(am4core.Container);
      nameAndButtonsContainer.width = am4core.percent(100);
      nameAndButtonsContainer.padding(10, 10, 5, 20);
      nameAndButtonsContainer.layout = "horizontal";

      // name of a country and date label
      let countryName = nameAndButtonsContainer.createChild(am4core.Label);
      countryName.fontSize = "1.1em";
      countryName.fill = am4core.color("#ffffff");
      countryName.valign = "middle";

      // buttons container (active/confirmed/recovered/deaths)
      let buttonsContainer = nameAndButtonsContainer.createChild(am4core.Container);
      buttonsContainer.layout = "grid";
      buttonsContainer.width = am4core.percent(100);
      buttonsContainer.x = 10;
      buttonsContainer.contentAlign = "right";

      // select a country
      function selectCountry(mapPolygon) {
        resetHover();
        polygonSeries.hideTooltip();

        // if the same country is clicked show world
        if (currentPolygon == mapPolygon) {
          currentPolygon.isActive = false;
          currentPolygon = undefined;
          showWorld();
          return;
        }
        // save current polygon
        currentPolygon = mapPolygon;

        // make others inactive
        polygonSeries.mapPolygons.each(function (polygon) {
          polygon.isActive = false;
        });

        // clear timeout if there is one
        if (countryDataTimeout) {
          clearTimeout(countryDataTimeout);
        }

        mapPolygon.isActive = true;
        // meaning it's globe
        if (mapGlobeSwitch.isActive) {
          mapChart.zoomToMapObject(mapPolygon, getZoomLevel(mapPolygon));
        } else {
          // animate deltas (results the map to be rotated to the selected country)
          if (mapChart.zoomLevel != 1) {
            mapChart.goHome();
            rotateAndZoom(mapPolygon);
          } else {
            rotateAndZoom(mapPolygon);
          }
        }
      }

      // what happens when a country is rolled-over
      function rollOverCountry(mapPolygon) {
        resetHover();
        if (mapPolygon) {
          mapPolygon.isHover = true;

          // make bubble hovered too
          let image = bubbleSeries.getImageById(mapPolygon.dataItem.id);
          if (image) {
            image.dataItem.dataContext["name"] = mapPolygon.dataItem.dataContext.name;
            image.isHover = true;
          }
        }
      }
      // what happens when a country is rolled-out
      function rollOutCountry(mapPolygon) {
        let image = bubbleSeries.getImageById(mapPolygon.dataItem.id);

        resetHover();
        if (image) {
          image.isHover = false;
        }
      }

      // rotate and zoom
      function rotateAndZoom(mapPolygon) {
        polygonSeries.hideTooltip();
        let animation = mapChart.animate(
          [
            { property: "deltaLongitude", to: -mapPolygon.visualLongitude },
            { property: "deltaLatitude", to: -mapPolygon.visualLatitude },
          ],
          1000
        );
        animation.events.on("animationended", function () {
          mapChart.zoomToMapObject(mapPolygon, getZoomLevel(mapPolygon));
        });
      }

      // calculate zoom level (default is too close)
      function getZoomLevel(mapPolygon) {
        let w = mapPolygon.polygon.bbox.width;
        let h = mapPolygon.polygon.bbox.width;
        // change 2 to smaller walue for a more close zoom
        return Math.min(mapChart.seriesWidth / (w * 2), mapChart.seriesHeight / (h * 2));
      }

      // show world data
      function showWorld() {
        currentPolygon = undefined;
        resetHover();

        if (countryDataTimeout) {
          clearTimeout(countryDataTimeout);
        }

        // make all inactive
        polygonSeries.mapPolygons.each(function (polygon) {
          polygon.isActive = false;
        });

        mapChart.goHome();
      }

      function handleImageOver(event) {
        rollOverCountry(polygonSeries.getPolygonById(event.target.dataItem.id));
      }

      function handleImageOut(event) {
        rollOutCountry(polygonSeries.getPolygonById(event.target.dataItem.id));
      }

      function handleImageHit(event) {
        selectCountry(polygonSeries.getPolygonById(event.target.dataItem.id));
      }

      function handleCountryHit(event) {
        selectCountry(event.target);
      }

      function handleCountryOver(event) {
        rollOverCountry(event.target);
      }

      function handleCountryOut(event) {
        rollOutCountry(event.target);
      }

      function resetHover() {
        polygonSeries.mapPolygons.each(function (polygon) {
          polygon.isHover = false;
        });

        bubbleSeries.mapImages.each(function (image) {
          image.isHover = false;
        });
      }

      rootStore.burnDrop.render();
      this.mapApi = {
        polygonSeries,
        selectCountry,
      };
    });
  }
}

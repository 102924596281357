import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { Explorer } from "./pages/Explorer";
import { ExplorerDetail } from "./pages/ExplorerDetail";
import { About } from "./pages/About";
import { MainLayout } from "./components/MainLayout/index";
import { useStore } from "../common/store/index";
import { useObserver } from "mobx-react-lite";

const App = () => {
  const { burnDrop: burndrop, lang } = useStore();
  useEffect(() => {
    lang.init();
    burndrop.loadLatestStats();
    const intervalId = setInterval(() => {
      burndrop.loadLatestStats();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  });
  return useObserver(() => (
    <MainLayout>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/explorer" component={Explorer} />
        <Route exact={true} path="/explorer/:id" component={ExplorerDetail} />
        <Route exact={true} path="/about" component={About} />
      </Switch>
    </MainLayout>
  ));
};

export default App;

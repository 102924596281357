import React, { useEffect } from "react";
import { useCountUp } from "react-countup";

interface IComponentProps {
  value?: number;
  label: string;
  unit: string;
  prefix: string;
}

export const StateItem = (props: IComponentProps) => {
  const { value, label, unit, prefix } = props;
  const { countUp, update } = useCountUp({
    start: 0,
    end: value,
    delay: 0,
    duration: 5,
  });

  const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    update(value);
  }, [value]);

  return (
    <div className="number-item text-left c-white mb-8 md:mb-0">
      <div className="text-xl xl:text-2xl tracking-wide font-thin leading-tight">
        {prefix} {value ? numberWithCommas(countUp) : ""} {unit}
      </div>
      <div className="tracking-wide text-sm xl:text-md font-thin">{label}</div>
    </div>
  );
};

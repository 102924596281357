import React from "react";
import { Header } from "../../components/Header";
import { css } from "../../modules/stitches";
import { ClientOnly } from "../ClientOnly/clientOnly";
import Helmet from "../HelmetMetadata/index";

interface IComponentProps {
  children: Array<JSX.Element> | JSX.Element;
}

css.global({
  html: {
    fontFamily: "Montserrat",
  },
});

const styles = {
  main: css({
    minHeight: "100vh",
    backgroundImage: `url(/image/bg.png)`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    color: "$white",
    fontFamily: "Montserrat",
  }),
  content: css({
    height: "calc(100% - 5rem)",
    overflow: "hidden",
  }),
};

export const MainLayout = (props: IComponentProps) => {
  return (
    <div>
      <Helmet />
      <ClientOnly>
        <div className={styles.main}>
          <Header />
          <div className={`${styles.content}`}>{props.children}</div>
        </div>
      </ClientOnly>
    </div>
  );
};

import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import { publicConfig } from "../../../../configs/public";

export default function Helmet() {
  let currentUrl = publicConfig.host;
  let quote = "";
  let title = publicConfig.shareConfig.title;
  let image = publicConfig.shareConfig.image;
  let description = publicConfig.shareConfig.description;
  const twitterMetas = [
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:site", content: "@iotex_io" },
    { property: "twitter:title", content: title },
    { property: "twitter:description", content: description },
    { property: "twitter:image", content: image },
  ];
  return (
    // @ts-ignore
    <ReactHelmet>
      {/* <title>{title}</title> */}
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={""} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={description} />
      {twitterMetas.map((i) => (
        <meta name={i.property} content={i.content} key={i.property} />
      ))}
    </ReactHelmet>
  );
}

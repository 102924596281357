import { utils } from "../src/common/utils/index";
const { NODE_ENV, FORCE_HTTPS } = utils.env.getEnv();

const IS_PROD = NODE_ENV == "production";
const host = "https://iott.network/";
export const publicConfig = {
  IS_PROD,
  IOTEX_CORE_ENDPOPINT: IS_PROD ? "https://api.iotex.one:443" : "https://api.testnet.iotex.one:443",
  IOTEXSCAN_ENDPOINT: IS_PROD ? "http://iotexscan.io" : "http://testnet.iotexscan.io",
  FORCE_HTTPS: utils.env.getBoolean(FORCE_HTTPS),
  host,
  shareConfig: {
    url: host,
    title: "The Internet of Trusted Things",
    image: "https://iotex.b-cdn.net/burndrop/iott_share_v1.png",
    shareTitle:
      'IoTeX is empowering the Internet of Trusted Things -- an open ecosystem where humans and machines can interact with trust, privacy, and free will. $IOTX \n\nExplore the global network of "Powered by IoTeX" devices at: ',
    description: "IoTT is an open ecosystem where humans and machines can interact with trust, privacy, and free will.",
  },
};

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { fromRau } from "iotex-antenna/lib/account/utils";
import { useLocalStore, useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../common/store/index";
import { _ } from "../../../common/utils/lodash";
import MapChart from "../../components/MapChart";
import { StateItem } from "../../components/StateItem";
import { css } from "../../modules/stitches";

export const Home = () => {
  const [showMore, setShowMore] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { burnDrop, lang } = useStore();
  const store = useLocalStore(() => ({
    get TotalDroppedIOTX() {
      return Number(fromRau(burnDrop.states.TotalDroppedIOTX, "iotx")) || 0;
    },
    get TotalBurntIOTX() {
      return Number(fromRau(burnDrop.states.TotalBurnIOTX || "0", "iotx"));
    },
    get CurrentYield() {
      return Number(burnDrop.states.CurrentYield || 0) * 100;
    },
    get regionsReached() {
      return Number(burnDrop.states.regionsReached || 0);
    },
    get totalDrppedevices() {
      if (burnDrop.viewType == "all") {
        return Number(burnDrop.states.TotalDroppedDevices || 0);
        // if (!burnDrop.totalDevices[burnDrop.totalDevices.length - 1]) return 0;
        // return Number(_.sum(burnDrop.totalDevices[burnDrop.totalDevices.length - 1].list.map((i) => i.total)));
      }
      if (burnDrop.viewType == "todayDelta") {
        return 0;
      }
      if (burnDrop.viewType == "week") {
        return 0;
      }
    },
    get lastDroppedDevices() {
      if (burnDrop.viewType == "todayDelta") {
        return Number(_.sum(burnDrop.todayData?.list.map((i) => i.delta)));
      }
      if (burnDrop.viewType == "all") {
        return 0;
      }
      if (burnDrop.viewType == "week") {
        return Number(_.sum(burnDrop.currentWeekData?.list.map((i) => i.delta)));
      }
    },
  }));

  const weekDevicesClick = (item, index) => {
    setCurrentIndex(index);
    burnDrop.selectWeek(item.date);
  };

  useEffect(() => {
    burnDrop.loadDevices().then(burnDrop.initMap);
  }, []);

  return useObserver(() => (
    <div className={styles.home}>
      {/* 地图 */}
      <div className={styles.mapChartBox}>
        <MapChart />
      </div>
      {/* 左侧数据 */}
      <div className={`pl-4 md:pl-12 lg:pl-12 xl:pl-24 ${styles.homeLeft}`}>
        <div className="mb-4"></div>
        <div className={`text-2xl md:text-4xl ${styles.title}`}>{lang.t("title")}</div>
        {burnDrop.viewType == "week" && <StateItem value={store.lastDroppedDevices} label={lang.t("devices.platform")} unit="" prefix="+" />}
        {burnDrop.viewType == "all" && <StateItem value={store.totalDrppedevices} label={lang.t("devices.platform")} unit="" prefix="" />}
        <StateItem value={store.regionsReached} label={lang.t("countries.regions.reached")} unit="" prefix="" />
        <div className="drivingTips">
          <StateItem value={store.TotalBurntIOTX} label={lang.t("iotx.burned")} unit="" prefix="" />
          <StateItem value={store.TotalDroppedIOTX} label={lang.t("iotx.dropped")} unit="" prefix="" />
          <a className="block flex items-center friend-ship" href="https://burndrop.iotex.io/">
            <span className="text-lg">{lang.t("explore.iotex.burn.drop")}</span>
            <img src="/image/right-arrow.png" alt="" />
          </a>
        </div>
      </div>
      {/* Join the Network */}
      <div className={styles.joinNetWork}>
        <a href="https://ucam.iotex.io/" target="_blank">
          {lang.t("join.the.network")}
        </a>
      </div>
      {/* 右侧时间轴 */}
      <div className={styles.timeLine}>
        <div className="timeLineMore block md:hidden flex items-center pb-4 pl-4 md:pl-0 mt-4  md:mt-0" onClick={() => setShowMore(!showMore)}>
          <span className="mr-2">{lang.t("more")}</span>
          {
            //@ts-ignore
            showMore ? <UpOutlined /> : <DownOutlined />
          }
        </div>
        <div className={`${showMore ? "block" : "hidden"} md:block mt-4 md:mt-0 timeLineBox`}>
          <div
            className="timeLineAll"
            onClick={(e) => {
              setCurrentIndex(-1);
              burnDrop.selectViewType("all");
            }}
          >
            <span className="circleDot"></span>
            <span className={currentIndex === -1 ? "active" : ""}>{lang.t("all")}</span>
          </div>
          <div className="timeLineWeek-scroll">
            <ul className="timeLineWeek">
              {burnDrop.weekDevices.map((item, index) => {
                return (
                  <li key={item.date} onClick={() => weekDevicesClick(item, index)}>
                    <span className="circleDot"></span>
                    <span className={`${currentIndex === index ? "active" : ""} date`}>
                      {lang.t("week")} {dayjs(item.date).format("DD/MM/YYYY")}
                    </span>
                    <div className="timeline"></div>
                    {index === 0 && <div className="timeline-first"></div>}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  ));
};

const styles = {
  home: css({
    width: "100%",
  }),
  joinNetWork: css({
    position: "relative",
    backgroundColor: "rgba(3,255,225,0.8)",
    borderRadius: "20px",
    padding: "0.4rem 3rem",
    textAlign: "center",
    maxWidth: "70%",
    marginTop: "1rem",
    marginLeft: "1rem",
    md: {
      position: "absolute",
      bottom: "10vh",
      right: "30vh",
      marginTop: "0",
    },
    a: {
      color: "#000",
      fontSize: "1rem",
      fontWeight: 600,
      "&:hover": {
        color: "#000",
      },
    },
  }),
  title: css({
    pointerEvents: "none",
    marginBottom: "3rem",
  }),
  homeLeft: css({
    position: "relative",
    md: {
      position: "absolute",
      height: "calc(100vh - 7rem)",
      overflow: "hidden",
      zIndex: "2",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
      pointerEvents: "none",
    },
    ".drivingTips": {
      width: "300px",
      padding: "25px 15px",
      boxSizing: "border-box",
      borderRadius: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      overflow: "hidden",
      textAlign: "center",
      pointerEvents: "auto",
      ".logo": {
        width: "128px",
        height: "128px",
        display: "inline-block",
      },
      ".vector_icon": {
        width: "1.2rem",
        display: "inline-block",
        cursor: "pointer",
      },
      ".number-item": {
        marginBottom: "4vh",
        div: {
          "&:first-child": {
            fontSize: "1.4rem",
          },
        },
        "&:nth-child(1)": {
          marginBottom: "6vh",
          div: {
            "&:first-child": {
              fontSize: "1.4rem",
            },
          },
        },
      },
      ".friend-ship": {
        color: "$green",
        img: {
          width: "1.4rem",
          height: "1.4rem",
          marginLeft: "1rem",
        },
      },
    },
    ".number-item": {
      marginBottom: "4rem",
      "&:first-child": {
        marginBottom: "2rem",
      },
      md: {
        marginBottom: "6vh",
      },
      "div:first-child": {
        fontSize: "2.6rem",
        color: "$green",
        fontWeight: 500,
        md: {
          fontSize: "4rem",
        },
      },
    },
  }),
  timeLine: css({
    position: "relative",
    md: {
      position: "absolute",
      width: "12rem",
      height: "calc(100vh - 7rem)",
      overflow: "hidden",
      zIndex: "2",
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
      pointerEvents: "none",
      right: "2rem",
    },
    ".timeLineMore": {
      color: "#03FFE1",
    },
    ".timeLineBox": {
      width: "100%",
      height: "55vh",
      position: "relative",
    },
    ".timeLineAll": {
      display: "flex",
      alignItems: "center",
      pointerEvents: "auto",
      cursor: "pointer",
      ".circleDot": {
        display: "block",
        width: "1.5rem",
        height: "1.5rem",
        border: "2px solid #fff",
        marginRight: "0.25rem",
        borderRadius: "50%",
      },
      span: {
        color: "rgba(255, 255, 255, 0.5)",
      },
      ".active": {
        color: "#fff",
      },
    },
    ".timeLineWeek-scroll": {
      position: "absolute",
      left: "0.53rem",
      top: "3vh",
      zIndex: 2,
      pointerEvents: "auto",
    },
    ".timeLineWeek": {
      // maxHeight: "40vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      li: {
        display: "flex",
        alignItems: "center",
        paddingBottom: "5vh",
        cursor: "pointer",
        pointerEvents: "auto",
        position: "relative",
        "&:first-child": {
          paddingTop: "5vh",
          ".timeline": {
            top: "calc(5vh + 17px)",
          },
          ".timeline-first": {
            position: "absolute",
            top: "0",
            left: "3px",
            width: "0.06rem",
            height: "calc(5vh + 8px)",
            backgroundColor: "#fff",
          },
        },
        ".active": {
          color: "#fff",
        },

        ".timeline": {
          position: "absolute",
          top: "16px",
          left: "3px",
          width: "0.06rem",
          height: "calc(5vh + 17px)",
          backgroundColor: "#fff",
        },
      },
      ".circleDot": {
        display: "block",
        width: "8px",
        height: "8px",
        border: "1px solid #fff",
        marginRight: "0.8rem",
        borderRadius: "50%",
      },
      ".date": {
        fontSize: "1rem",
        color: "rgba(255, 255, 255, 0.5)",
      },
    },
    ".timeLinePloy": {
      width: "0.06rem",
      height: "105%",
      background: "#fff",
      position: "absolute",
      left: "0.2rem",
      top: 0,
    },
  }),
  mapChartBox: css({
    width: "100%",
    height: "55vh",
    md: {
      width: "100%",
      height: "calc(100vh - 5rem)",
      boxSizing: "border-box",
      position: "absolute",
      top: "5rem",
      left: "0",
    },
  }),
};
